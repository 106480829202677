<template>
<div>
     <v-app-bar
      color="#1c63ba"
      dark
      flat
      class="mt-15"
    >
     <v-toolbar-title>
        <span>
           {{Store.name}}
        </span>
    </v-toolbar-title>
      <v-spacer></v-spacer>      
      <v-text-field
        flat
        rounded
        class="mx-4 storeSearch"
        hide-details
        label="Search"
        v-model="text"
        prepend-inner-icon="mdi-magnify"
        solo-inverted
      ></v-text-field>
        <v-spacer></v-spacer>

      <div v-if="SearchData.length > 0" class="storeSearchData">
        <div class="clearbtn">
              <v-spacer></v-spacer>
          <v-btn @click="clearsearch()" text color='info' small>Clear All</v-btn>
        </div>
      <v-divider light></v-divider>
      <v-list-item v-for="n in SearchData" :key="n._id"  class="stateDate">
        <v-list-item-avatar>
          <v-icon
            class="grey lighten-1"
            dark
          >
            mdi-folder
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="black--text">{{n.name}}</v-list-item-title>
          <v-list-item-subtitle class="black--text">{{n.name}} || {{DisplayCurrency(n.SellingPrice)}}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
            <v-btn @click="addToCart(n)"
             dark
                small
                color="#1c63ba"
                >
               <span class="btntex"> Add to cart</span>
                </v-btn>
        </v-list-item-action>
      </v-list-item>
      </div>
    </v-app-bar>
     <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
    >
    <SideMenuList/>
    </v-navigation-drawer>
     <Loading v-if="Loading" />
<v-container>
  <div v-if="!Products.length == 0" class="main">
    <div
    :key='index' v-for="(i, index) in Products" 
      class="pa-3 item"
    >
      <v-img
        lazy-src="https://picsum.photos/id/11/10/6"
          :src="i.Imageurl ? DomainImage+i.Imageurl :'https://storage.googleapis.com/gravicart/Rectangle%2010.png'"
        max-height="150"
        max-width="250"
        class="vimg"
      >
      </v-img>
      <div class="pt-6 itemm">
        <div>
            <div class="time">
            <div>
                <span class="clock">{{i.name}}</span>
            </div>
        </div>
        <div class="mb-4">
       <div class="Area line-clamp">
         <p>
       {{i.description}}
         </p>
       </div>
        </div>
        <div class="time">
            <div>
                <span class="clock">{{DisplayCurrency(i.SellingPrice, i.storeId.currency)}}</span>
            </div>
            <v-spacer/>
             <v-btn
             @click="addToCart(i)"
             dark
                small
                color="#1c63ba"
                >
               <span class="btntex"> Add to cart</span>
                </v-btn>
        </div>
        </div>
      </div>
    </div>
  </div>
    <div v-if="Products.length == 0" class="main">
    <div :key='index' v-for="(i, index) in 4" 
      class="mx-auto pa-3 item"
    >
      <v-img
        lazy-src="https://picsum.photos/id/11/10/6"
          src="https://picsum.photos/id/11/10/6"
        max-height="150"
        max-width="250"
        class="vimg"
      >
      </v-img>
      <div class="pt-6 itemm">
        <div>
            <div class="time">
            <div>
                <span class="clock">-------</span>
            </div>
        </div>
        <div class="mb-4">
       <div class="Area line-clamp">
         <p>
           --------
         </p>
       </div>
        </div>
        <div class="time">
            <div>
                <span class="clock">{{DisplayCurrency(0, "NGN")}}</span>
            </div>
            <v-spacer/>
             <v-btn dark
                small
                color="#1c63ba"
                >
               <span class="btntex"> Add to cart</span>
                </v-btn>
        </div>
        </div>
      </div>
    </div>
    </div>
     <v-pagination
      v-model="page"
      :length="Math.round(totalItems/20)"
      circle
    ></v-pagination>

       <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="dialog"
        persistent
      >
          <v-card>
            <v-toolbar
              color="primary"
              dark
            >Warning !!!</v-toolbar>
            <v-card-text class="pt-5">
              <div class="display-1 centerText font-weight-normal underline">You are about to add item(s) from a different Store.</div>
              <br>
              <div class="display-1 centerText font-weight-normal underline">This Action will clear your existing cart</div>
              <br>
              <p class="display-1 centerText font-weight-normal underline">if you continue</p>
            </v-card-text>
            <v-card-actions class="justify-center pb-5">
              <v-btn text @click="ClearCart()">Continue</v-btn>
              <v-btn @click="dialog = false">Close</v-btn>
            </v-card-actions>
          </v-card>
      </v-dialog>

</v-container>
</div>
</template>

<script>
import {GetStoreProducts, SearchStoreProduct, DomainImage} from '../APIs'
import axios from "axios";
import Loading from '../components/Loading'
import SideMenuList from '../components/SideMenuList'
import currencyFormatter from 'currency-formatter'

export default {
     metaInfo: {
    title: `Store Inventory`,
    meta: [
      {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      {name: 'description', content: 'Ecommerce'}
    ]
  },
     components:{
        Loading,
        SideMenuList
      },
    created(){
    this.getProduct()
    this.DomainImage = DomainImage
    this.$store.commit("setLoading", true)
  },
    data: () => ({
      drawer: false,
      Products:[],
      text:"",
      page:1,
      Currpage:1,
      SearchData:[],
      dialog:false,
      Store:{},
      totalItems:0,
      DomainImage:""
    }),
      watch: {
      text (val) {
        if(val.length > 2){
          this.SearchStoreProduct()
        }
    },
      page: function (val) {
      if(this.page != this.Currpage){
        this.getProduct()
        console.log("PP", val)
        this.Currpage=val
      }
      }
  },
  computed:{
       Loading() {
          return this.$store.getters.Loading;
        },
     GetCartStore(){
       return this.$store.getters.Store
     }
  },
 methods:{
   ClearCart(){
     this.dialog = false
       this.$store.commit("ClearCart", [])
   },
   clearsearch(){
     this.SearchData = []
   },
      SearchStoreProduct(){
          this.$store.commit("setLoading", true)
          axios.put(`${SearchStoreProduct}`,{
            text:this.text,
            storeId:this.$route.params.id
          })
          .then(res => {
              this.SearchData= res.data.Products
              console.log(res.data.Products)
              if(res.data.Products.length == 0){
              this.$store.commit("setSnackbar", true);
              this.$store.commit("setServerMessage", "no records")
              }
          })
          .catch(err => {
            console.log(err.data, "GetStores: ERR")
          })
          .finally(()=>{
             this.$store.commit("setLoading", false)
          })
      },
      DisplayCurrency(payload, payload2) {
    return currencyFormatter.format(payload, {
        code: payload2
    });
    },
   addToCart(payload){
        if(this.GetCartStore != "" && this.GetCartStore._id != payload.storeId._id){
       this.dialog = true
       return
     }

    if(!payload.quantity){
      payload.quantity = 1
    }
      this.$store.commit("setSnackbar", true)
      this.$store.commit("setServerMessage", `${payload.name} Added`)
     this.$store.commit("addItem", payload)
   },
   getProduct(){
       this.$store.commit("setLoading", true)
        axios.put(`${GetStoreProducts}?page=${this.page}`,{
          storeId: this.$route.params.id
        })
          .then(res => {
            this.Products = res.data.Products
            this.Store = res.data.store
            this.totalItems = res.data.totalItems
            if(res.data.Products.length == 0){
                 this.$store.commit("setSnackbar", true)
                this.$store.commit("setServerMessage", "No Item")
            }
          })
          .catch(err => {
            console.log(err.data, "GetStores: ERR")
            this.$store.commit("setSnackbar", true)
            this.$store.commit("setServerMessage", err.data)
          })
          .finally(()=>{
             this.$store.commit("setLoading", false)
          })
   }
 }
}
</script>

<style scoped>
.main{
    min-height: 100vh;
    padding-top: 50px;
    display: flex;
    flex-wrap: wrap;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
.vimg{
    border-radius: 0px;
    overflow: hidden;
}
.firstline{
    display: flex;
}
.itemm{
  margin-top: 5px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 20px 10px 40px 40px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}
.item{
  width: 265px;
  margin: 10px;
  min-height: 250px;
  background-color: transparent;
  border-radius: 0px;
}
.SUPERMARKET {
  font-family: Poppins;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #000039;
}
.State {
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
  background-image: linear-gradient(to top, #f2994a, #eed72b);
}
.Stores-Head{
  margin: 20px 47px 0px 5px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #1156aa;
}
.Area{
  margin: 0 0 0 5px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #374146;
  height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;  
}
.time{
    display: flex;
}
.clock{
  margin: 10px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #1c63ba;
}
.btntex{
  font-size: 9px !important;  
}
.colorr{
    background-color: #1c63ba;
}
.storeSearch{
 position: relative; 
}
.storeSearchData{
   position: absolute;
  max-height: 200px;
  width: 470px;
  background-color: white;
  right: 23%;
  z-index:100;
  top: 60px;
  border-radius: 10px;
  overflow: scroll;
}
.clearbtn{
  display: flex;
}
.centerText{
  text-align: center;
}
@media only screen and (max-width: 700px) {
  .btnn{
    display: none;
  }
.storeSearchData{
  position: absolute;
  max-height: 200px;
  width: 470px;
  background-color: white;
  z-index:100;
  right: 0px;
  top: 60px;
  border-radius: 10px;
  overflow: scroll;
}
}
</style>